// @flow

import React from 'react';
import styled from 'styled-components';

const BackGroundImg = styled.img`
  width: 100%;
`;

export const Header = ({ imgSrc }) => {
  return <BackGroundImg src={imgSrc} alt='Me' />;
};
