// @flow

import React from 'react';
import styled from 'styled-components';

import { Paragraph } from './theme/components/Text';

const P = styled(Paragraph)`
  font-size: 22px;
  line-height: 42px;
`;

function Intro() {
  return (
    <div>
      <P>
        The festive 500 is an informal challenge organized by the cycling
        clothing brand Rapha. The goal is to ride 500km between Christmas Eve
        and New Year's Eve. I've decided to try to complete the challenge this
        year with my brother Yuzo in Normandy since we'd be spending the
        holidays in the family home.
      </P>
      <P>
        Spending that much time on the bike at the end of the year means the
        participants will have plenty of time to reflect on year that’s just
        passed. Perhaps my greatest achievement this year has been inspiring
        friends to ride by sharing my adventures. This webpage is intended both
        as an entry to the festive 500 contest which rewards the best stories
        and hopefully an inspiration for some to start riding.
      </P>
    </div>
  );
}

export default Intro;
