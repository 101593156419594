// @flow

import React from 'react';
import styled from 'styled-components';

const FullWidthImg = styled.img`
  width: 100%;
  /* margin: 32px; */
`;

const LargeImgCtn = styled.div`
  max-width: 860px;
  margin: 32px auto;
  padding: 32px;
  background: #ccc;
`;

function LargeImg(props: { src: string }) {
  return (
    <LargeImgCtn>
      <FullWidthImg src={props.src} />
    </LargeImgCtn>
  );
}

export { FullWidthImg, LargeImg };
