// @flow

import brotonneBridge from './images/IMG_20171224_123622.jpg';
import cowSeine from './images/IMG_20171224_132821.jpg';
import swamp from './images/IMG_20171224_140458.jpg';
import curesMarines from './images/IMG_20171224_160251.jpg';
import christmasTree from './images/IMG_20171225_133019.jpg';
import beaumont from './images/IMG_9476.JPG';
import fields from './images/IMG_20171225_134352.jpg';
import yuzoWithLeaves from './images/IMG_20171225_134812.jpg';
import meAtTheSeaSide from './images/IMG_9478.JPG';
import yuyuinTheForest from './images/IMG_20171226_121915.jpg';
import meGoingDown from './images/IMG_9495.JPG';
import yuzuInTheHobbit from './images/IMG_20171226_123738.jpg';
import meTakingPics from './images/IMG_9502.JPG';
import lego from './images/IMG_20171226_145705.jpg';
import aDashOfRed from './images/IMG_20171227_125911.jpg';
import inBetweenTwoCliffs from './images/IMG_9543.JPG';
import cows from './images/IMG_20171228_121750.jpg';
import yuyuInFields from './images/IMG_20171228_121024.jpg';
import yuyuGoingDownTinyRoads from './images/IMG_20171228_140238.jpg';
import yuyuInSunset from './images/IMG_20171228_161722.jpg';
import panorama from './images/IMG_20180102_122754.jpg';

const stages = [
  {
    day: 'Day 01',
    title: 'Accross Normandy',
    description: [
      'Today is the 24th of December 2017. The day has finally come. My brother, myself and all and many other cyclists all over the globe have until the end the year 2017 to ride 500km. So is the precept of the festive 500.',
      'Our first stage consists of a 120km ride from basecamp, my parents home in Normandy to Deauville, on the other side of the Seine river, accross Normandy.',
      "We'll be following the riverbed of the Seine for the better part of the itinerary on a cold but mostly dry day.",

      "We start at 11, later than planned as usual. My brother is anxious, he hasn't ridden his bike for a couple of months. He keeps telling me that 120km makes for a big and difficult ride in the Winter.",
      "We start easy, on quite familiar roads. We reach the banks of the Seine by 1PM. We cross the river on a very cycle friendly bridge to follow its course on the other side. The weather is rather cold and my brother is dying for a sandwich or anything that could constitute lunch. He's grown tired of our homemade cereal bars. However we can't find any bakeries or shops among the many thatched houses that we slowly pass by.",
      'The view gets spectacular as the road rises up. I think of my itinerary drawn in a couple of minutes on Strava with a satisfied grin.',
      'We should do this again on a glorious summer day in 6 months surounded by friends.',
      'We finally reach Honfleur, 20km from Deauville. We eat a camembert with a glass of rancid <i>vin chaud</i> before the following our course along the seaside accompanyied by a rider from Holland.',
      "It's now time to prepare for christmas eve!",
    ],
    images: [brotonneBridge, cowSeine, swamp, curesMarines],
  },
  {
    day: 'Day 02',
    title: "Le Pays d'Auge",
    description: [
      "We set off for a short ride around Deauville. We both have a sore knee so we keep the pace leisurely. The landscape reminds me of my first solo century across Normandy on easter back in April. I then discovered the charms of <i>le pays d'Auge</i>. Strangely, I had rolled through this region by car hundreds of times and never realised how beautiful it was.",
      "I love riding bicycles because they are the best tools to discover places. They allow one to contemplate glorious sceneries at the right pace. Slow enough so that one does not miss a thing but fast enough to cover significant ground. It's probably the main reason I ride my bike!",
    ],
    images: [christmasTree, beaumont, fields, yuzoWithLeaves, meAtTheSeaSide],
  },
  {
    day: 'Day 03',
    title: 'The Valley of Love',
    description: [
      "Back in basecamp, we roll out for a 70kms loop eastwards. The roads look familiar for the most part but we try to take routes we haven't ridden before. We cross valleys with enchanting views and roll on the Greenway, an old railway converted into a cycle lane that goes from Paris to London.",
      'All in all, a quiet ride with frozen feet. The perfect way to get away from all the noise, preoccupations, questions and anxieties focusing on a simple matter, pushing on the pedals and keeping your hands on the bars and eyes on the horizon. Living in the moment, however painful or joyous it might be.',
    ],
    images: [yuyuinTheForest, meGoingDown, yuzuInTheHobbit, meTakingPics, lego],
  },
  {
    day: 'Day 04',
    title: 'Frozen hands',
    description: [
      'The weather is getting worse by the day. We decide to have a go despite the constant downpour of rain. 10 minutes into the ride hailstones start stinging our faces. Intense face wind and near 0 temperatures make it impossible to ride. We turn back smashing our pedals as hard as we can. Our hands and feet go utterly numb.',
      'Back in the warmth of home we enter into a half hour of pain while blood pushes its way back to the tip of our fingers. We wait for the pain to stop, moaning and cursing. It does eases up steadily, so much so that we don’t even notice it.',
    ],
    images: [aDashOfRed],
  },
  {
    day: 'Day 05',
    title: 'On the white cliffs of Normandy',
    description: [
      "On the fifth stage, we set off for the coast. It's the first sunny day since Christmas! Both with creaky knees, my brother and I drift towards the coast.",
      "We arrive at a small town nested in between two cliffs. It's one of the few spots where the road meets the see before going back up on the cliff. Most of the time we feel the presence of the sea but can only guess that it's there hidden down the precipice. It’s playing hide and seek! Some tiny steep roads venture into the breaches of the ground towards the beach for breathtaking views.",
      'After a coffee break we reach Dieppe and Pourville to shoot the panorama the same way we do every time we pass by. Time to get back to basecamp before sunset.',
    ],
    images: [
      cows,
      yuyuInFields,
      yuyuGoingDownTinyRoads,
      inBetweenTwoCliffs,
      yuyuInSunset,
    ],
  },
  {
    day: 'Day 06',
    title: 'Abort! Abort!',
    description: [
      'Having suffered from frozen finger tips a few days earlier we wanted to avoid riding in the rain again. So we kept looking out the window, scanning the horizon for signs of bright intervals. One came shortly after lunch. We decided to try our luck and rushed outside. Ten short minutes in however we seeked protection from a hedge before turning around. With two days to go our chances of reaching our goals seemed slim since we’d both be spending the last day of the year with friends.',
      'The following day, we would have to go all in.',
    ],
  },
  {
    day: 'Day 07',
    title: 'Classic rides never get old',
    description: [
      'For our last ride of 2017, we have decided to go the easy way by sticking to the route we know best. We ride in the valley until the sea, then along the sea to reach another quiet valley that brings us right back home. Trying to cover the distance in spite of our tired legs. A little detour towards the end would allow us to complete the festive 500.',
      'Not such an uneventful ride though, The fatigue accumulated over the week meant that we had to convince ourselves that the last 30 kilometres were worth it. But that was easy, we recalled our dark times on the bike during our biggest days in the saddles, our greatest adventures, because the harder it gets the bigger the reward.',
      'The year 2017 was the year we went out of our confort zone and rode more than 200 kilometres in a day. That gave us a sense of achievement and pride, confidence in our bodies and machines. I felt like I could go further, unstoppable almost, free and that gave me tremendous joy.',
    ],
    images: [panorama],
  },
];

export default stages;
