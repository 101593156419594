// @flow

const colours = {
  darkGrey: '#777',
};

const layout = {
  leftMarginWidth: '200px',
};

export { colours, layout };
