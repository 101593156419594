// @flow
import styled from 'styled-components';

const Paragraph = styled.p`
  font-family: 'Cormorant Garamond', serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
`;

const TextCtn = styled.div`
  max-width: 700px;
  margin: 32px auto;
  padding: 0 16px;
`;

export { Paragraph, TextCtn };
