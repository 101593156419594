import styled from 'styled-components';

import R0000462 from './assets/R0000462.jpg';
import R0000498 from './assets/R0000498.jpg';
import R0000569 from './assets/R0000569.jpg';
import R0000618 from './assets/R0000618.jpg';
import R0001244 from './assets/R0001244.jpg';
import R0001290 from './assets/R0001290.jpg';
import R0001535 from './assets/R0001535.jpg';
import R0001599 from './assets/R0001599.jpg';
import R0001972 from './assets/R0001972.jpg';
import R0001994 from './assets/R0001994.jpg';
import R0002017 from './assets/R0002017.jpg';
import R0002303 from './assets/R0002303.jpg';
import R0002416 from './assets/R0002416.jpg';
import R0002426 from './assets/R0002426.jpg';
import R0002642 from './assets/R0002642.jpg';
import R0002763 from './assets/R0002763.jpg';
import R0002832 from './assets/R0002832.jpg';
import R0002842 from './assets/R0002842.jpg';
import R0003070 from './assets/R0003070.jpg';
import R0003284 from './assets/R0003284.jpg';
import R0003316 from './assets/R0003316.jpg';

const photos = [
  R0000462,
  R0000498,
  R0000569,
  R0000618,
  R0001244,
  R0001290,
  R0001535,
  R0001599,
  R0001972,
  R0001994,
  R0002017,
  R0002303,
  R0002416,
  R0002426,
  R0002642,
  R0002763,
  R0002832,
  R0002842,
  R0003070,
  R0003284,
  R0003316,
];

export const Photos = () => (
  <Wrapper>
    {photos.map((photo) => (
      <ImageWrapper>
        <Image src={photo} />
      </ImageWrapper>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const Image = styled.img`
  height: auto;
  width: auto;
`;

const ImageWrapper = styled.div`
  max-width: 600px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 20px;
`;
