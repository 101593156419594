import React from 'react';

import stages from './stages';
import Stage from '../../ride/Stage';
import Header from './Head';
import Footer from '../../theme/components/Footer';

export const Festive500 = () => (
  <div>
    <Header />
    {stages.map((stage, index) => (
      <Stage key={index} stage={stage} />
    ))}
    <Footer />
  </div>
);
