import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { dark } from '../colors';

export const Header = () => (
  <Wrapper>
    <HeaderLink to='/'>Kenzo Naito</HeaderLink>
  </Wrapper>
);

const Wrapper = styled.div`
  background-color: ${dark};
  width: auto;
  padding: 10px 20px;
  height: 19px;
  /* position: fixed; */
`;

const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
`;
