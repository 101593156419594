// @flow

import React from 'react';
import styled from 'styled-components';

const Header = styled.div`
  overflow: hidden;
  position: relative;
`;

const TitleCtn = styled.div`
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const H1 = styled.h1`
  font-family: 'Cormorant Garamond', serif;
  font-style: italic;
  font-size: 92px;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
`;

function Title() {
  return (
    <Header>
      <TitleCtn>
        <H1>Stories of festive 500 from a random cyclist</H1>
      </TitleCtn>
    </Header>
  );
}

export default Title;
