import React from 'react';
import styled from 'styled-components';
import { Title } from './theme/components/Title';
import { StyledLink } from './theme/components/Link';
import { Photos } from './images';

export const Home = () => (
  <Wrapper>
    <Title>
      <StyledLink to='tprno2'>Transpyrenees Race 2 - français</StyledLink>
    </Title>
    <Title>
      <StyledLink to='tour-du-pic-du-midi-d-ossau'>
        Tour du pic du midi d'Ossau - français
      </StyledLink>
    </Title>
    <Title>
      <StyledLink to='festive-500'>
        Stories of festive 500 from a random cyclist - English
      </StyledLink>
    </Title>
    <Photos />
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;
