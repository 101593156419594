import firstImage from './images/R0002021.jpg';
import secondImage from './images/R0002017.jpg';
import thirdImage from './images/R0002026.jpg';
import fourthImage from './images/R0002029.jpg';

import toothBrush from './images/PXL_20211231_230111228.jpg';
import bivouac from './images/R0002039.jpg';
import breakfast from './images/R0002042.jpg';
import traverse from './images/R0002069.jpg';
import crampons from './images/R0002061.jpg';
import fireplace from './images/R0002105.jpg';

import gave from './images/R0002110.jpg';
import lunch from './images/R0002115.jpg';
import waterBottle from './images/R0002127.jpg';
import couple from './images/R0002122.jpg';
import gege from './images/R0002137.jpg';

export const copy = [
  {
    day: 'Jour 1',
    title: 'Décalage horaire',
    description: [
      "L'équipe se rejoint à Pau pour 3 jours de marche autour du pic du midi d'Ossau. Les prévisions météo sont excellentes et le risque d'avalanche limité, des conditions parfaites pour marquer le passage à la nouvelle année. Nous sommes 5, bons marcheurs, sportifs et vaccinés. Nous profitons du déjeuner au soleil à Laruns avant de louer le matériel de sécurité, les raquettes et les crampons recommandés par l'antenne locale du peloton de gendarmerie de haute montagne (PGHM).",
      "Nous partons en direction du col du Pourtalet pour rejoindre le départ de la randonnée conscients que nous allons devoir marcher de nuit. Nous ne sommes pas inquiets puisque nous n'avons que 5 kms pour rejoindre le refuge de Pombie où nous avons prévu de passer la nuit.",
      "Il y a beaucoup de monde au col, venu profiter du très beau temps et de l'enneigement exceptionnel en ce mois de décembre. C'est visiblement l'heure de redescendre, les skieurs, amateurs de raquettes et de descentes en luges rejoignent leur voiture. Nous chaussons les raquettes alors que le soleil couchant illumine le ciel et les montagnes enneigées de couleurs exotiques. J'ai l'impression que les gens nous regardent de travers. Que font ces urluberlus à partir au coucher du soleil ? L'idée de marcher à contre courant me plaît, c'est l'assurance de se retrouver seuls dans la nature pendant quelques jours.",
      "Le début de l'ascension vers le col de Soum de Pombie se fait à allure modérée, la semaine de fêtes de fin d'année se fait sentir. Nous suivons les pas de dizaines de personnes avant nous, le chemin est tout tracé. Nous allumons nos frontales pour atteindre le col et basculer vers le refuge. Une forme sombre immense se dresse sur notre gauche. Il n'y a pas de doute, il s'agit du pic du midi d'Ossau dont nous sommes venus faire le tour. Il sera comme notre nord pendant les jours à venir, nous voulons en observer tous les visages.",
      'Yann, un skieur local venu chercher un peu de tranquillité nous accueille au refuge de Pombie.',
    ],
    images: [firstImage, secondImage, thirdImage, fourthImage],
  },
  {
    day: 'Jour 2',
    title: 'En dilettante',
    description: [
      "Je suis réveillé au petit matin par deux espagnols, vraisemblablement venus gravir le pic. J'entends un \"loco\" fuser, je devine qu'il m'est adressé puisque que je suis en train de dormir à la belle étoile. Ils se délestent de quelques affaires superflues avant de repartir. Nous prenons le temps de petit déjeuner en profitant de la vue. Nous ne sommes pas encore rodés et nous mettons beaucoup de temps à faire nos sacs. Chacun y va de son petit oubli.",
      'Nous partons vers 11 heures, sans réaliser que la neige a déjà transformé dans les versants sud-est dans lesquels nous évoluons. Nous progressons lentement dans une soupe semblable à de la glace de poissonnier.',
      "Nous suivons des traces de skis faute de mieux, le sentier d'été est caché sous une épaisse couche de neige. Marcher en travers de la pente en raquettes est très désagréable et le col qui semblait si proche s'éloigne tant nous nous enfonçons. La neige devient dure par endroit dans les pentes moins ensoillées. Nous devons chausser les crampons et monter pour trouver un chemin plus sûr.",
      "Nous arrivons au col de Suzon peu après midi. Le groupe a surmonté la difficulté avec une certaine aise, je suis électrique ! Nous avons certes perdu du temps et quelques forces. Qu'importe, à partir de maintenant nous allons parcourir les kilomètres restants comme nous en avons l'habitude. Mon optimisme est sans faille, la descente vers le lac de Bious Artigues est à notre avantage.",
      "Il n'en est rien. Nous tergiversons sur l'itinéraire à prendre pour passer au dessus de la rivière. L'énervement et la fatigue se font grandissants. La neige exige tour a tour que nous enfilions crampons puis raquettes. La conclusion est inévitable : nous ne rallierons pas le refuge d'Ayou, notre objectif de la journée, sans marcher quelques heures de nuit.",
      "Nous traversons des bois où nous nous enfonçons lourdement dans une neige humide. Je ne prends pas la mesure de la fatigue du groupe. Je refuse d'accepter qu'un changement de plan initial est nécessaire tant je connais les ressources de amis. Je redoute de ne pouvoir accomplir le tour prévu.",
      "Une fois arrivé au point le plus bas en altitude de la journée, le moral deux groupe est atteint. Nous prenons quelques minutes pour évaluer nos options. Abandonner maintenant en redescendant vers la route quelques kilomètres en contre bas pour y faire du stop ? S'obstiner à monter vers le refuge à 5km plus loin. Nous discutons avec les randonneurs qui redescendent. Il faut encore 3h de marche pour atteindre le refuge d'Ayou. Par chance, une troisième voie s'offre à nous, il y a, à quelques centaines de mètres une cabane où nous pouvons passer la dernière soirée de l'année.",
      "Nous changeons notre programme. Nous ferons un petit tour vers les lacs demain avant de descendre faire du stop. Le pic est toujours là, nous narguant de toute sa hauteur. Pour la deuxième fois consécutive cet hiver, je ne vais pas au bout. Nous partageons notre dîner avec deux randonneurs venus chercher un abris pour la nuit après un coup de propre. L'opération Pimp my cabane est un succès ! Nous trouvons même les ressources pour attendre minuit avant de se coucher !",
    ],
    images: [bivouac, breakfast, traverse, crampons, fireplace, toothBrush],
  },
  {
    day: 'Jour 3',
    title: 'Le caprice',
    description: [
      "Le réveil sonne. J'ai dormi à côté de la cafetière italienne qui m'accompagne toujours en montagne. Je tends le bras pour allumer le réchaud depuis mon sac de couchage. Une idée me trotte dans la tête. La voiture se trouve à une distance de 14km, 1000m plus haut. Je suis en forme et je sais que mon pied est sûr. Vu l'heure qu'il est, je pense pouvoir rallier la ligne d'arrivée sans risque ou faire demi tour avant la tombée de la nuit. J'aurais les réponses quand à la difficulté du parcours bien assez rapidement.",
      "Je rallume le foyer de la cheminée pour encourager mes camarades à sortir de leur sac de couchage. Je commence à rassembler mes affaires. Mavrick remarque que je me hâte. Je n'ai pas besoin d'expliciter mon projet, il le fait pour moi. A part Eugénie, personne ne semble s'en offusquer. Eugénie connait bien les Pyrénées. Je veux prouver qu'il est possible de de se tenir à notre plan sans prendre de risques inconsidérés, que nous ne devrions pas abandonner pour si peu, que nous avons payé hier notre nonchalance face à la montagne. Je veux aussi me prouver que je suis capable de faire demi tour dans des situations douteuses.",
      "Après avoir pris part au rangement collectif, je chausse mes raquettes. Le départ de ce matin contraste avec celui d'hier. La neige est bien croûtée dans le vallon à l'ombre. Je progresse vite, sans effort. Au bout de quelques kilomètres, je reçois un message du groupe. Ils refusent de me laisser continuer seul. Je décide de les attendre à l'embranchement ou nos chemins devaient se séparer.",
      "Le vallon au fond duquel je patiente m'offre une vue magnifique sur le pic Castérau, cousin miniature du Matterhorn. Je suis un peu agacé, je me refroidis vite à l'ombre et je perds un temps précieux. La suite du parcours est exposé sud-ouest, la neige est déjà en train de se ramollir.",
      "Après 20min d'attendre, je décide de leur accorder 20min de plus, 10h45 dernier carat. Je grignote des fruits secs en regardant la lumière du soleil taper sur les pics environnants. C'est tellement beau que rien ne semble pouvoir atteindre mon humeur. Je me questionne sur la source de ma volonté. Suis je vraiment handicapé par un ego surdimensionné ?",
      "10h45 sonnent, je balance mon sac a dos sur mon épaule. Un dernier coup d'œil au dessus de mon épaule, j'aperçois une, puis deux silhouettes familières. Je n'ai pas eu le temps de faire 20 mètres.",
      'Eugénie trouve mon comportement lamentable pour des raisons de sécurité mais le considère aussi comme une trahison. Nous sommes un groupe indivisible pour le temps de la randonnée, tel est le contrat que nous avons formé implicitement.',
      "Au bluff, je leur propose de tenter de rallier l'arrivée prévue initialement. À ma grande surprise tout le monde semble d'accord. Adrianna est dubitative et nous rappelle nos difficultés de la veille. Nous tombons sur un accord. Si nous atteignons le sommet du pas avant 12h30, nous continuerons, aussi, nous verrons bien la tête du pas une fois en bas.",
      "Celui-ci a l'air raide mais un passage bien droit se dessine. Nous l'attaquons avec le sourire. Quelques mètres plus haut, les sourires se crispent. La pente est si raide qu'une avalanche s'est déclenchée bien avant notre arrivée laissant la partie supérieure à nue. Nous, nous hissons tant bien que mal sur cette partie en terre. Mes amis n'ont pas l'air a l'aise du tout. Il nous faut sortir de cette situation rapidement au risque de s'épuiser et de faire une erreur. Il y a en dessous de nous une longue pente très raide. J'aide Eugénie à retirer ses raquettes en regardant Simon et Mavrick progresser à 4 pattes. Comme eux, Adrianna grimpe raquettes aux pieds. Une porte de sortie semble se dessiner sur la gauche sous la forme d'un replat. Eugénie commence à déraper lentement au dessus de moi. J'attrape une sangle de son sac. J'ai la gorge complètement sèche, je suis inquiet. Je m'agite pour aider mes camarades, il faut montrer le chemin. Nous nous extirpons vers un replat bienvenu au prix d'un gros coup de stress.",
      "Le groupe est un peu sonné. Comment avons nous pu nous exposer aussi rapidement ? Nous avons vécu les conséquences d'une prise de décision distribuée. Personne n'a pris la responsabilité de trouver une trace sans risque. Nous avons fait ce que les humains font de mieux, continuer dans la même direction sans prendre en considération les éléments à disposition.",
      "Nous faisons une pause au soleil. Simon suggère que nous appelions le PGHM pour savoir quelle option nous est plus favorable entre rebrousser chemin et continuer vers le col de l'Iou. Je m'exécute. Comme d'habitude, le gendarme au bout du fil est agréable et d'une efficacité redoutable. Il nous localise à l'aide d'un SMS, puis nous recommande de continuer. Nous avons fait le plus dur, il n'y a aucune difficulté si nous empruntons la trace qui mène au col de l'Iou. Nous sommes rassurés, comme libérés d'un poids.",
      "Je crois fondamentalement au pouvoir de la bêtise. Faire une erreur et en comprendre les conséquences est bien plus riche qu'une mise en garde si l'on en tire les lessons. Cette expérience doit être un garde fou, comme une claque face à un danger en sommeil. En décrire tous les détails dans ce court récit est, à mon sens, une façon de les garder en tête durablement.",
      "Nous parcourons les derniers kilomètres jusqu'au col le coeur léger. Le chemin est non seulement sans risques mais le décor est grandiose. Nous brisons la glace du lac pour remplir nos gourdes avant d'entamer la descente. C'est si beau que j'en suis ému. La vue a un petit goût d'accomplissement malgré les péripéties. Je mesure combien nous avons appris et combien nous avons à apprendre. Nous reviendrons.",
    ],
    images: [gave, lunch, waterBottle, couple, gege],
  },
];

// Aucun membre du groupe n'est capable de décrire le terrain de ces 5 kms. Personne n'est au courant de la technicité requise, nous raisonnons dans des repères de randonnée d'été ou de randonnée de moyenne montagne que nous connaissons. Nous sommes, de ce fait, confiants que les 3 prochains jours vont être une ballade de santé.
