// @flow

import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Festive500 } from './pages/festive500';
import { Home } from './Home';
import { Ossau } from './pages/ossau';
import { Tprno2 } from './pages/tprno2';
import { Header } from './theme/components/Header';
import Footer from './theme/components/Footer';

const App = () => (
  <BrowserRouter>
    <Header />
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='tour-du-pic-du-midi-d-ossau' element={<Ossau />} />
      <Route path='festive-500' element={<Festive500 />} />
      <Route path='tprno2' element={<Tprno2 />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);

export default App;
