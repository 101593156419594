import React from 'react';
import Stage from '../../ride/Stage';
import { copy } from './copy';

export const Tprno2 = () => (
  <>
    {copy.map((day) => (
      <Stage stage={day} />
    ))}
  </>
);
