// @flow

import React from 'react';
import styled from 'styled-components';
import { dark } from '../colors';

const Wrapper = styled.div`
  display: flex;
  margin: 20px auto;
  width: 200px;
  justify-content: space-between;
  background: dark;
`;

const StyledLink = styled.a`
  text-align: center;
  color: black;
`;

function Footer() {
  return (
    <Wrapper>
      <StyledLink href='https://strava.com/kenzo.naito'>Strava</StyledLink>
      <StyledLink href='https://instagram.com/kenzo.naito'>
        Instagram
      </StyledLink>
    </Wrapper>
  );
}

export default Footer;
