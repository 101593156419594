// @flow

import React from 'react';
import styled from 'styled-components';

import Day from './Day';
import { Title } from '../theme/components/Title';
import Description from './Description';
import Images from './Images';

const LayoutCtn = styled.div`
  max-width: 700px;
  margin: 32px auto;
  padding: 16px;
`;

const DayCtn = styled.div`
  display: flex;
  align-items: baseline;
`;

const RideBulk = styled.div``;

function Stage(props: { stage: Stage }) {
  return (
    <div>
      <LayoutCtn>
        <DayCtn>
          <Day>{props.stage.day}</Day>
          <RideBulk>
            <Title>{props.stage.title}</Title>
            <Description>{props.stage.description}</Description>
          </RideBulk>
        </DayCtn>
      </LayoutCtn>
      <Images images={props.stage.images} />
    </div>
  );
}

export { Stage as default, LayoutCtn };
