import startline from './images/startline.jpg';
import pourtalet from './images/pourtalet.jpg';
import map from './images/map.jpg';
import banyuls from './images/banyuls.jpg';
import andorre from './images/andorre.jpg';
import pottok from './images/pottok.jpg';
import pailheres from './images/pailheres.jpg';
import ciel from './images/ciel.jpg';
import antoine_tristan from './images/antoine_tristan.jpg';
import vaches from './images/vaches.jpg';
import estive from './images/estive.jpg';
import corentin from './images/corentin.jpg';
import ardoise from './images/ardoise.jpg';
import aspin from './images/aspin.jpg';
import aubisque from './images/aubisque.jpg';
import marie_blanque from './images/marie_blanque.jpg';
import moutons from './images/moutons.jpg';
import bagargi from './images/bagargi.jpg';

export const copy = [
  {
    title: 'TPRNo2 - Tout le plaisir est pour nous',
    description: [
      "Prendre du plaisir : c'est le message de la plupart de mes amis cyclistes qui m'encouragent avant le départ. Ils savent à quel point je fais une montagne de cette course à laquelle j'ai déjà participé trois ans auparavant. Traverser les Pyrénées dans le sens de la longueur, non pas une mais deux fois en moins de 7 jours, le temps d'un long weekend pour les meilleurs.",
      "La mission me paraît impossible, \"j'ai vu les chiffres, j'ai compris\" me dit Samuel Becuwe casquette 141, plusieurs Transcontinentales dans la musette. Plus de 30000 m de dénivelé positif sur 1600 km dont plus de la moitié est un concentré de tour de France entre Ax les Thermes et Laruns. L'aller, côté espagnol, a donc un air de vacances pendant lesquelles il ne sera pas question de se dorer la pilule. L'objectif est d'entamer le chemin du retour avant le quatrième jour si l'on veut se laisser une chance d'arriver avant la voiture balais.",
      "Moi aussi, j'ai compris l'effort que ça représentait en 2019 où j'ai peiné à terminer, en plus de 7 jours, 12 heures après la fin du temps imparti. C'est la raison pour laquelle je redoute cette deuxième édition. J'y vais même à reculons tant cette première épreuve m'a couté mentalement. Plus jamais, avais-je dis à Julien Gravaud (casquette 66) lors de la première édition.",
      'Je me suis laissé convaincre de rempiler par un ami rencontré sur la course en 2019 qui avait fini comme moi hors délais. J’ai envie de terminer ce que j’ai entrepris il y a 3 ans, de me prouver que j’en suis capable. Le défi m’effraie. Je ne veux pas revivre la soupe à la grimace de 2019, les doutes face aux gares croisées sur le chemin. Je fais de mon objectif principal de prendre du plaisir ! Je suis en vacances après tout. A quelques semaines du départ, c’est la perspective de passer une semaine dans les montagnes qui me conduit sur la ligne de départ. Je vais prendre la température, jour après jour. Si le moral est bon, le reste suivra.',
      "Je retrouve l'ambiance de l'avant course à Saint Jean de Luz, la kermesse de passionnés, de mordus, un peu fous aussi. J'ai hâte d'en découdre, au moins pour en finir avec l'appréhension. Je suis prêt à voir la vérité en face. La météo a tourné, il devrait faire beau sur tout le parcours à l'exception de la première demie journée. C'est difficile à croire, tant il pleut aujourd'hui.",
    ],
    images: [],
  },
  {
    day: 'Jour 1',
    description: [
      "Je m'élance de la ligne de départ étrangement serein. Cette première journée devrait m'apporter des éléments de réponse quant à ma forme et à ma motivation. Je ne veux pas m'arrêter dormir trop tôt, pour prendre de l'avance, ne pas reproduire les erreurs de la première édition. Je reçois assez vite des messages qui me tiennent au courant de mon avancée, je suis dans les 20 premiers à passer le checkpoint 1. Je sais que ça ne durera pas, mais ça me rassure et me pousse. Pour y croire, prendre du plaisir, je dois aussi prendre part à la compétition parce que c'en est une et que j'aime ça.",
      "Je bascule dans la descente du CP et profite d'une longue section de plat que j'ai choisie pour progresser rapidement, couché sur les prolongateurs, poussé par le vent. Je suis confiant d'avoir fait le choix optimal dans mon tracé, ça m'évite de tergiverser, de m'interroger sur un gâchis éventuel. Le répit est de courte durée. Devant moi, se cache le col du Pourtalet. 27 km de montée avec vue sur les sommets enneigés aux alentours. C'est tellement beau que le temps d'un instant je suis ému aux larmes. Voilà ce que je suis venu chercher ici.",
      "Je dévore ma première tortilla accompagnée de ses croquetas dans la venta située au sommet. Les Espagnols savent recevoir, dînent tard et conduisent très respectueusement. C'est un paradis pour nous qui accumulons les bornes, cherchant à souper le plus tard possible avant de nous assoupir quelques heures.",
      "Je fais des calculs savants pour savoir où dormir ce soir. Trop loin et l'hôtel sera fermé, trop proche et je n'aurais pas atteint mon objectif du jour. Je calcule aussi combien de dénivelé et de kilomètres il me reste à parcourir. Je me rassure en me disant que cette grosse première journée va me rendre la tâche moins difficile ! Je vais même jusqu'à rêver de journées tranquilles. Je m'arrête finalement à Escalona, quelques minutes avant la fermeture de la cuisine où la réceptionniste me conduit avant toute formalité administrative.",
      "Je règle mon réveil sur 5 heures, en dormir 6 devrait être suffisant. Je cherche à me reposer suffisamment pour ne pas éteindre mon réveil et refermer les yeux. Si je n'ai pas assez dormi, je serais trop irritable pour profiter de la course, sans parler de récupération.",
    ],
    images: [startline, pourtalet, map],
  },
  {
    day: 'Jour 2',
    description: [
      "Au petit matin, je m'élance pour la deuxième fois dans l'obscurité. C'est un passage obligé pour un événement en octobre. Je ne suis pas très à l'aise dans l'exercice qui ressemble à une simulation de privation sensorielle, une plongée dans le noir. Je mets de la musique pour me distraire. Mes amis m'ont concocté une playlist avant le départ.",
      "J'arrive au pied d'un col non asphalté au lever du jour. C'est l'inconnu du parcours. Les pentes exigeantes m'obligent à me déplacer à vitesse réduite. Je suis équipé de mon vélo de route avec des pneus de 28mm, ça secoue. Au sortir de la descente, ma roue avant émet un bruit suspect. Je pense d'abord à de la terre accumulée dans la fourche mais c'est bien du centre de la roue que vient le problème. Qu'importe, pour le moment ça roule.",
      "Je rejoins mes concurrents au café du village de Castejón de Sos situé en contrebas. La plupart ont parcouru les 30 km en plus de 3h. Certains ont crevé, d'autres ont chuté dans la descente. Il semble que tout le monde ait limité la casse. Je me remets rapidement en route, j'aimerais atteindre le checkpoint en Andorre avant la nuit. Je profite d'un parcours relativement roulant pour accumuler les bornes et engranger des points de confiance. La machine à calculer des moyennes fonctionne à plein régime, je suis bien dans les temps.",
      "J'arrive à Seu d'Urgell avant la tombée de la nuit. J'ai opté pour un aller retour jusqu'au CP à partir d'ici au lieu du sentier, plus direct. Je passe donc la soirée à croiser des concurrents dans les 2 sens au milieu de ce gigantesque centre commercial ouvert 24 heures sur 24. Le passage en Andorre me fait immédiatement regretter l'Espagne. Nous sommes dans la principauté de la pétarade et du plus pressé. Je me fais allègrement klaxonner, je n'ai rien à faire sur la route aux yeux des locaux.",
      "L'accès à l'hôtel qui sert de checkpoint est une succession de murs que j'escalade dans l'obscurité la plus totale. Je suis satisfait, j'ai atteint mon objectif aujourd'hui. Je valide le CP3 avant de foncer dans la descente retrouver l'Espagne, un bon dîner et un sommeil récupérateur.",
    ],
    images: [vaches, andorre],
  },
  {
    day: 'Jour 3',
    description: [
      "Je me lève avec une seule idée en tête, faire tamponner mon brevet sur les bords de la Méditerranée avant d'entamer le chemin du retour. C'est pour moi la seule façon d'être dans les temps à l'arrivée. Je m'élance dans un froid sec. La météo est si clémente que des montgolfières décollent une à une du fond de la vallée.",
      "L'expresso que j'avale à la va-vite dans un café ne passe pas. L'odeur douteuse de cette caverne à insomniaques me donne envie de vomir. Je me force à rester sur mon vélo malgré les secousses. J'avale des bonbons pour faire passer le mal, “happy life” dit le paquet.",
      "J'ai en ligne de mire les remontes pentes d'une station de ski, point culminant de ma journée. Un col plus loin, j'aperçois la mer. Celle-ci semble si proche, je pourrais sans doute y descendre en quelques minutes en volant. Mes espoirs de baignade s'éteignent dès la descente terminée. Je dois d'abord traverser le magnifique parc naturel d'Albera. Pas de doute, je me rapproche, les cactus bordent la route et il fait beaucoup plus chaud. Je devine la mer derrière une grande bute que je finis par franchir au coucher du soleil. Banyuls s'offre à moi sous une lumière orangée. Quelle récompense après 3 jours d'efforts.",
      "Reste à escalader la tour Madeloc pour atteindre le phare du cap Béar qui accueille le checkpoint. Je maudis l'organisation de nous avoir condamné à passer par là. Le paysage est probablement époustouflant mais je ne vois rien puisqu'il fait complètement noir. Je devine la mer en contrebas, légèrement éclairée par les villages de la côte. Je me dis qu'il faudra bien que je revienne un jour, admirer ce que j'ai raté.",
    ],
    images: [banyuls],
  },
  {
    day: 'Jour 4',
    description: [
      "Je démarre ma quatrième journée sous un ciel en feu. Les jambes sont dures, j'espère qu'aujourd'hui sera le jour le plus compliqué avant d'être le témoin de la formidable capacité d'adaptation du corps humain. J'ai jusqu'ici réussi à m'astreindre à 16 heures de selle quotidiennes tout en dormant 6 heures par nuit. Je sens que je n'avance plus au même rythme, rien d'étonnant. Antoine (casquette 15) et Tristan (casquette 96) que je croise à plusieurs reprises sont dans le même état que moi.",
      "Dans le col de Garabeil, mon GPS m'indique que les pentes sont sur le point de devenir quasi verticales. Je souris, ça doit être une erreur. Quelques instants plus tard je me dresse sur les pédales, poussant de toutes mes forces pour ne pas poser le pied. Je force tant et si bien que mon talon d'Achille droit finit par grincer. J'espère que la douleur n'est que passagère.",
      "Malheureusement le port de Pailhères n'est pas le meilleur remède. Je continue de forcer pour atteindre le sommet à 2000 mètres d'altitude. Je suis heureux de retrouver ces paysages de hautes montagnes qui m'entourent.",
      "Les derniers kilomètres avant d'atteindre mon étape pour la nuit sont une fois de plus plus compliqués que prévu. Je serpente sur les crêtes au-dessus de Tarascon. La route alterne entre montée et descente en dents de scie. Mon talon d'Achille me fait un mal de chien et me fait perdre patience. Je teste différentes positions pour soulager la douleur. Je lutte pour me concentrer en pensant au dîner. Quelques insultes m'échappent dans l'obscurité.",
      "C'est en avalant mon cornet de frites que je décide de m'accorder 2 heures de sommeil supplémentaires cette nuit pour essayer de récupérer un peu. J'ai peur que la course ne se transforme en cauchemar si je dois lutter avec la douleur.",
    ],
    images: [ciel, pottok, pailheres, antoine_tristan],
  },
  {
    day: 'Jour 5',
    description: [
      "J'ai bu 2 litres d'eau avant de me coucher. J'étais probablement déshydraté. Je me masse le talon d'Achille avec une crème anti-inflammatoire. Ça tire encore. Je me remets en selle, je verrai bien en route. Par chance, j'ai l'occasion de me tester progressivement dans les premiers kilomètres de la journée. La douleur a disparu. Je m'interroge, était-ce un état de fatigue générale ? Une déshydratation avancée ?",

      "J'atteins le sommet du port de l'Hers avec soulagement. Mon humeur s'accorde bien avec l'ambiance festive qui y règne. Des enfants en sortie scolaire côtoient des retraités s'activant autour d'un barbecue. Plus loin, je suis arrêté par un troupeau de vaches qui descend. C'est la fin de l'estive, celles-ci regagnent la vallée où elles passeront l'hiver au chaud. Elles vont faire la moitié du trajet en camion. Les gens ont changé, me dit l'éleveuse, ils ne veulent plus de bouses sur la route.",

      "Les cols défilent, je profite de l'un deux pour discuter avec Corentin (casquette 33), un ami que je croise pour la première fois depuis le départ. Nous vient l'envie de pousser un peu plus loin ensemble mais nous sommes rattrapés par le règlement. Cette course doit se faire sans assistance, y compris celle d'autres coureurs. Partager un hébergement voudrait dire enfreindre les règles. Nous ne nous y risquons pas, malgré la frustration. Je m'arrête donc à Luchon après une petite journée. Je sais que mon programme pour demain est chargé, pas moins de 6 ascensions m'attendent.",
    ],
    images: [estive, corentin],
  },
  {
    day: 'Jour 6',
    description: [
      "Je connais bien le profil de la journée qui s'annonce, je l'ai déjà parcouru lors de ce qui fut probablement ma meilleure journée sur un vélo. Peyresourde, Aspin, Tourmalet, Soulor, Aubisque, Marie-Blanque. J'espère en être encore capable.",
      "J'ai assez rapidement la réponse puisque je franchis le sommet du premier col dans la noir complet. Je suis en avance sur mon fantôme d'il y a 3 ans, comme dans un jeu vidéo. Je franchis le col d'Aspin au petit matin, c'est magnifique. Je suis dérangé par un couinement assez fort émanant de ma roue avant. Celà fait maintenant 2 jours que je m'inquiète sur son sort. Elle ne roule désormais plus très droit et offre une légère résistance dont je me passerais bien.",
      "Je profite du Tourmalet pour passer quelques coups de fils à des amis qui me conseillent de changer de roue pour ne pas risquer mon aventure. Je prends le temps au sommet de passer commande d'une roue neuve montée avec un pneu et une chambre à air. Dans la descente j'ai peur que ma roue se bloque, peut-être suis-je devenu un peu paranoïaque. Je ne suis pas aussi à l'aise que d'habitude sur cette route on l'on peut rapidement atteindre les 80km/h.",
      "C'est donc avec soulagement que je remplace ma roue à Argelès Gazost au pied du Soulor. Je m'accorde même la première vraie pause déjeuner depuis que je suis parti. Je dévore une série de quiches et de desserts confortablement installé dans un canapé.",
      "J'ai déjà avalé 3000m de dénivelé aujourd'hui, je suis dans les temps. Je pense grimper encore 3 cols avant la tombée de la nuit. Je me demande si je n'en profiterais pas pour rallier la ligne d'arrivée sans m'arrêter. L'idée me trotte dans la tête depuis ce matin. Je fais des provisions mais je m'aperçois rapidement que je ne suis pas large. Je m'arrête donc quand un restaurant surgit de nulle part au détour d'un virage. Celui-ci fait aussi hôtel. Il reste bien une chambre mais le lit est cassé me dit-on. Je m'en accommoderai.",
    ],
    images: [aspin, aubisque, marie_blanque],
  },
  {
    day: 'Jour 7',
    description: [
      "Je réalise le lendemain que j'ai fait la bonne opération. La suite du parcours est embuée dans un épais nuage. Décidément, je n'aurais pas pu avoir plus de chance. Je profite d'un café ouvert exceptionnellement dans la descente du col de la Pierre Saint Martin. J’ai d’abord cru à un mirage, ça fait 20 km que j'en rêve !",
      "Il ne me reste qu'un mur a passer avant l'arrivée. Je mets un point d'honneur à ne pas poser le pied alors que les panneaux indiquant 12% se succèdent. Je suis debout, assénant des coups de pédales tel un boxeur. Puis, tout d'un coup, c'est terminé, je suis venu à bout du géant.",
      "Je fonce vers l'océan couché sur ma machine, je suis attendu. Un coup d'œil au panneau Saint Jean de Luz qui m'émeut puis j'y suis. J'y ai pensé des centaines de fois au début du parcours. Quand je croiserai cette route à nouveau, j'en aurais fini. J'ai largement dépassé mes attentes. Non seulement ai-je rejoint la ligne d’arrivée dans les temps mais j’ai passé une semaine le sourire aux lèvres, à me faire chatouiller par les rayons du soleil, poussé par le vent. Bien loin de la semaine en enfer que j’anticipais. Je retrouve ce sentiment grisant de fierté qu’on atteint en s’étonnant de ses propres actions.",
      "J'inscrit mon nom sur l'ardoise à la suite des 40 autres qui m'ont précédé. Il est 17h, heure à laquelle il était prévu que j'arrive avant de prendre le départ. On ne fait jamais mieux que l'objectif qu'on s'était fixé sur les longues distances. Le mien était de prendre du plaisir et il est doublement rempli.",
    ],
    images: [moutons, bagargi, ardoise],
  },
];
