import React from 'react';
import Stage from '../../ride/Stage';
import { copy } from './copy';
import { Header } from './Header';
import map from './images/Pyrenees_topographic_map-en.png';

export const Ossau = () => (
  <div>
    <Header imgSrc={map} />
    {copy.map((stage) => (
      <Stage stage={stage} />
    ))}
  </div>
);
