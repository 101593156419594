import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../theme/components/Text';

const P = styled(Paragraph)`
  max-width: 400px;
`;

function Description(props) {
  return (
    <div>
      {props.children.map((child, index) => (
        <P dangerouslySetInnerHTML={{ __html: child }} key={index} />
      ))}
    </div>
  );
}

export { Description as default, Paragraph };
