// @flow

import React from 'react';

import { LargeImg } from '../theme/components/Images';

function Images(props: { images: string[] }) {
  return (
    <div>
      {props.images &&
        props.images.map((image, index) => (
          <LargeImg key={index} src={image} />
        ))}
    </div>
  );
}

export { Images as default };
