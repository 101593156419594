// @flow

import React from 'react';

import { TextCtn } from '../../theme/components/Text';
import { Separator } from '../../theme/components/Separator';
import Title from './Title';
import Intro from '../../Intro';

function Head() {
  return (
    <div>
      <TextCtn>
        <Title />
        <Separator />
        <Intro />
      </TextCtn>
    </div>
  );
}

export default Head;
