// @flow
import styled from 'styled-components';
import { colours } from '../../theme.js';

const Separator = styled.div`
  width: 32px;
  height: 1px;
  background: ${colours.darkGrey};
  margin: 32px 0;
`;

export { Separator };
